import React, { useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import { history, store } from './store/appStore';
import { ConnectedRouter } from 'connected-react-router';
//import { App } from './App';
//import SGLongFormTour from '../src/SGLongFormTour'
import { mergeStyles } from '@fluentui/react';
import * as serviceWorker from './serviceWorker';

import SiteLoaderContainer from './components/siteLoading/SiteLoaderContainer';
import LayoutContainer from './components/layoutComponent/LayoutContainer';
import { LoadingComponent } from './components/common/LoadingComponent';
import TourLandingComponentContainer from './components/tourLanding/TourLandingComponentContainer';
import LegacyComponentContainer from './components/shortComponents/legacy/LegacyComponentContainer';
import TourEndComponentContainer from './components/tourEnd/mainComponent/TourEndComponentContainer';
import TourTypeSelectorContainer from './components/common/TourTypeSelectorContainer';

import './index.css';
// import LongFormCollectionRedirectContainer from './components/common/LongFormCollectionRedirectContainer';
import CollectionAndTourLandingComponentContainer from './components/collectionAndTourLanding/CollectionAndTourLandingComponentContainer';

import { updateMktoCookieExpiration } from './cookieHandler';

// Define the type for the 'to' prop
interface ExternalRedirectProps {
	to: string;
  }
  
  const ExternalRedirect: React.FC<ExternalRedirectProps> = ({ to }) => {
	useEffect(() => {
        console.log("Redirecting to: ", to);
	  window.location.href = to;
	}, [to]);
  
	return null;
  };

// Inject some global styles
mergeStyles({
	selectors: {
		':global(body), :global(html), :global(#root)': {
			margin: 0,
			padding: 0,
			height: '100vh',
		},
	},
});

export const defaultLocale = navigator.language;

setTimeout(() => {
    updateMktoCookieExpiration(300);
}, 1000); // Adjust the time (5000ms here) as needed


ReactDOM.render(
	<Suspense fallback={<LoadingComponent />}>
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<SiteLoaderContainer>
					<LayoutContainer>
						<Switch>
							<Route
								exact
								path="/:selectedLocale/guidedtour/"
								component={LegacyComponentContainer}
							/>
							<Route
								exact
								path="/guidedtour/"
								component={LegacyComponentContainer}
							/>
							<Route
								exact
								path="/guidedtour/landing"
								component={LegacyComponentContainer}
							/>
							<Route
								exact
								path="/:selectedLocale/guidedtour/landing"
								render={(props) => (
									<CollectionAndTourLandingComponentContainer
										{...props}
										isNativeLongForm={false}
									/>
								)}
							/>

							{/* External Redirect */}
							<Route
								exact
								path="/:selectedLocale/guidedtour/power-platform/power-virtual-agents/"
								render={() => <ExternalRedirect to="https://www.microsoft.com/en-us/microsoft-copilot/microsoft-copilot-studio" />}
							/>
							
							{/* Internal Redirects */}
							<Redirect from="/:selectedLocale/guidedtour/dynamics/copilot-for-service/" to="/:selectedLocale/guidedtour/microsoft-365/microsoft-365-copilot-for-service/" />
							<Redirect /* Handle missing locale with default locale */
								exact
								from="/guidedtour/landing/"
								to={`/${defaultLocale}/guidedtour/landing`}
							/>

							<Redirect from="/:selectedLocale/guidedtour/dynamics/supply-chain-management/" to="/:selectedLocale/guidedtour/dynamics/supply-chain-modernization/" />
							<Redirect /* Handle missing locale with default locale */
								exact
								from="/guidedtour/landing/"
								to={`/${defaultLocale}/guidedtour/landing`}
							/>

							<Redirect from="/:selectedLocale/guidedtour/dynamics/optimize-financial-and-operating-models/" to="/:selectedLocale/guidedtour/dynamics/optimize-finance-and-supply-chain/" />
							<Redirect /* Handle missing locale with default locale */
								exact
								from="/guidedtour/landing/"
								to={`/${defaultLocale}/guidedtour/landing`}
							/>

							<Redirect from="/:selectedLocale/guidedtour/dynamics/modernize-the-service-experience/" to="/:selectedLocale/guidedtour/dynamics/modernize-service/" />
							<Redirect /* Handle missing locale with default locale */
								exact
								from="/guidedtour/landing/"
								to={`/${defaultLocale}/guidedtour/landing`}
							/>

							<Redirect from="/:selectedLocale/guidedtour/dynamics/enable-always-on-service/" to="/:selectedLocale/guidedtour/dynamics/modernize-service/" />
							<Redirect /* Handle missing locale with default locale */
								exact
								from="/guidedtour/landing/"
								to={`/${defaultLocale}/guidedtour/landing`}
							/>

							<Redirect from="/:selectedLocale/guidedtour/dynamics/activate-digital-selling/" to="/:selectedLocale/guidedtour/dynamics/accelerate-revenue-generation/" />
							<Redirect /* Handle missing locale with default locale */
								exact
								from="/guidedtour/landing/"
								to={`/${defaultLocale}/guidedtour/landing`}
							/>

							<Redirect exact path="/" to="/guidedtour/" />
							<Redirect
								exact
								path="/:selectedLocale/guidedtour/:collectionId"
								to="/:selectedLocale/guidedtour/:collectionId/landing"
							/>
							<Redirect /* Handle missing locale with default locale */
								exact
								from="/guidedtour/:collectionId/"
								to={`/${defaultLocale}/guidedtour/:collectionId/landing`}
							/>
							<Route
								exact
								path="/:selectedLocale/guidedtour/:collectionId/landing"
								component={TourLandingComponentContainer}
							/>
							<Redirect
								exact
								from="/:selectedLocale/guidedtour/:collectionId/:scenarioId"
								to="/:selectedLocale/guidedtour/:collectionId/:scenarioId/1/1"
							/>
							<Redirect /* Handle missing locale with default locale */
								exact
								from="/guidedtour/:collectionId/:scenarioId/"
								to={`/${defaultLocale}/guidedtour/:collectionId/:scenarioId/1/1`}
							/>
							<Redirect
								exact
								from="/:selectedLocale/guidedtour/:collectionId/:scenarioId/:taskId/"
								to="/:selectedLocale/guidedtour/:collectionId/:scenarioId/:taskId/1"
							/>
							<Redirect /* Handle missing locale with default locale */
								exact
								from="/guidedtour/:collectionId/:scenarioId/:taskId"
								to={`/${defaultLocale}/guidedtour/:collectionId/:scenarioId/:taskId/1`}
							/>
							<Redirect /* Handle missing locale with default locale */
								exact
								from="/guidedtour/:collectionId/:scenarioId/:taskId/:stepId"
								to={`/${defaultLocale}/guidedtour/:collectionId/:scenarioId/:taskId/:stepId`}
							/>
							<Route
								path="/:selectedLocale/guidedtour/:collectionId/demo-end/:tourId"
								component={TourEndComponentContainer}
							/>
							<Redirect /* Handle missing locale with default locale */
								exact
								from="/guidedtour/:collectionId/demo-end/:tourId"
								to={`/${defaultLocale}/guidedtour/:collectionId/demo-end/:tourId`}
							/>
							<Route
								path="/:selectedLocale/guidedtour/:collectionId/:tourId/:sectionId/:stepId"
								component={TourTypeSelectorContainer}
							/>
							{/* Attempting to router from an lfguidedtour location to collectionLandingPage with LongForm collections.  */}
							{/*<Route*/}
							{/*    exact*/}
							{/*    path="/:selectedLocale/lfguidedtour/landing"*/}
							{/*    component={LongFormCollectionRedirectContainer}*/}
							{/*/>*/}
							<Redirect
								from="/lfguidedtour"
								to="/en-us/guidedtour/healthcare-longform"
							/>
						</Switch>
					</LayoutContainer>
				</SiteLoaderContainer>
			</ConnectedRouter>
		</Provider>
	</Suspense>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA


const updateHandler = (registration: ServiceWorkerRegistration) => {
	if (window.confirm("Update available. Do you want to reload?")) {
		window.location.reload();
	}
};
serviceWorker.register({
	onUpdate: updateHandler,
});
